@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Custom styles */
.crypto-card {
  @apply bg-white rounded-lg shadow-md p-4 transition-all duration-300 hover:shadow-lg;
}

.crypto-title {
  @apply text-xl font-semibold text-gray-800 mb-2;
}

.crypto-value {
  @apply text-2xl font-bold text-blue-600;
}

.crypto-change-positive {
  @apply text-green-600 font-semibold;
}

.crypto-change-negative {
  @apply text-red-600 font-semibold;
}

.chart-container {
  @apply w-full h-64 md:h-80 lg:h-96;
}

/* Responsive adjustments */
@media (max-width: 640px) {
  .crypto-title {
    @apply text-lg;
  }

  .crypto-value {
    @apply text-xl;
  }
}